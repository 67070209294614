exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-danse-horaires-js": () => import("./../../../src/pages/danse/horaires.js" /* webpackChunkName: "component---src-pages-danse-horaires-js" */),
  "component---src-pages-danse-js": () => import("./../../../src/pages/danse.js" /* webpackChunkName: "component---src-pages-danse-js" */),
  "component---src-pages-danse-tarifs-js": () => import("./../../../src/pages/danse/tarifs.js" /* webpackChunkName: "component---src-pages-danse-tarifs-js" */),
  "component---src-pages-fitness-horaires-js": () => import("./../../../src/pages/fitness/horaires.js" /* webpackChunkName: "component---src-pages-fitness-horaires-js" */),
  "component---src-pages-fitness-js": () => import("./../../../src/pages/fitness.js" /* webpackChunkName: "component---src-pages-fitness-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infos-js": () => import("./../../../src/pages/infos.js" /* webpackChunkName: "component---src-pages-infos-js" */)
}

